<!--
/**
 *@author hhy
 *@date 2023年2月9日
  财务管理 对账管理 互联互通账单*/
-->
<template>
  <custom-list
    search-btn-text="查询"
    reset-btn-text="恢复默认"
    table-title="互联互通账单"
    warm-tips="温馨提示：请选择流量方查看数据。"
    search-param-validate-err-msg="请选择流量方名称进行查询"
    :show-header="true"
    :request-call="false"
    :search-data="searchData"
    :search-params="searchParam"
    :table-columns="tableColumns"
    :search-params-validate-keys="['userId', 'userName']"
    :request-method="reconciliationApi.interconnectedList"
    :header-buttons="pageButtons.filter(item => item.enabled())"
    :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
    @query-form="handleSearchConfirm"
    @clear-form="handleSearchClean" />
</template>

<script>
  import { reconciliationApi } from '@/service/apis';
  import { code, getLastTime, getLastYear, offlineExport } from '@/utils';
  import CustomList from '@/components/biz/CustomList.vue';

  export default {
    name: 'InterconnectedBillingStatementsList',
    components: { CustomList },
    data() {
      return {
        id: '',
        isEdit: false,
        allZdUsers: [],
        reconciliationApi,
        polymerizationInfo: {},
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            enabled: () => code('finance:reconci:inter:export'),
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              offlineExport(
                {
                  downloadType: 'LINK_BILL',
                  jsonNode: {
                    ...ctx.searchParam,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '账单时间', prop: 'summaryDate', minWidth: '140px' },
          { label: '流量方名称', prop: 'userName', minWidth: '160px' },
          { label: '充电量', prop: 'totalDegree', minWidth: '100px' },
          { label: '标准价电费', prop: 'totalStandardChargeFee', minWidth: '100px' },
          { label: '标准价服务费', prop: 'totalStandardServiceFee', minWidth: '100px' },
          { label: '平台电费活动抵扣', prop: 'totalPlatformChargeDiscountAmt', minWidth: '150px' },
          {
            label: '平台服务费活动抵扣',
            prop: 'totalPlatformServiceDiscountAmt',
            minWidth: '150px',
          },
          { label: '商户电费活动抵扣', prop: 'totalOperatorChargeDiscountAmt', minWidth: '150px' },
          {
            label: '商户服务费活动抵扣',
            prop: 'totalOperatorServiceDiscountAmt',
            minWidth: '150px',
          },
          { label: '实付电费', prop: 'totalSettleChargePrice', minWidth: '100px' },
          { label: '实付服务费', prop: 'totalSettleServicePrice', minWidth: '100px' },
        ],
        tableOperateButtons: [
          // {
          //   id: '1',
          //   text: '编辑',
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/financeManage/agentCommissionApplication/edit',
          //       query: { id: row.id },
          //     });
          //   },
          // },
          {
            id: '2',
            text: '明细',
            enabled: () => code('finance:reconci:inter:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/interconnectedBillingStatement/interconnectedBillingStatementsDetail',
                query: {
                  id: row.userId,
                  tradeTime: row.summaryDate,
                  queryType: this.searchParam.queryType,
                },
              });
            },
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        searchParam: {
          accountTime: [],
          billStartDay: '',
          billEndDay: '',
          queryType: '',
          userName: '',
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            isDateType: true,
            label: '账单时间',
            key: 'accountTime',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            key: 'userId',
            label: '流量方名称',
            placeholder: '请输入流量方名称',
            comName: 'YkcDropdown',
            change: val => {
              const item = this.allZdUsers.find(elem => elem.id === val);
              this.searchParam.userName = item?.name;
            },
            data: this.allZdUsers,
            remote: true,
          },
        ];
      },
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.accountTime = [startDate, endDate];
      this.searchParam.billStartDay = startDate;
      this.searchParam.billEndDay = endDate;

      this.queryAllZdlUser();
    },

    methods: {
      /**
       * 获取流量方
       */
      queryAllZdlUser() {
        reconciliationApi.queryAllZdlUser({ keyword: '' }).then(res => {
          this.allZdUsers = (res || []).map(({ id, name }) => ({
            name,
            id,
          }));
        });
      },
      /**
       * 切换日月
       */
      handleChange(obj) {
        console.log('obj===', obj);
        const { select, event } = obj;

        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(2, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.accountTime = [startDateStr, endDateStr];
          this.searchParam.billStartDay = startDateStr;
          this.searchParam.billEndDay = endDateStr;
          this.searchParam.queryType = '2';
        } else if (event === 'select' && select === 'daterange') {
          console.log(' this.searchParam++++', this.searchParam);
          // 切换到日
          const startDateStr = getLastTime('yyyy-MM-dd', 7);
          const endDateStr = getLastTime('yyyy-MM-dd', 1);

          this.searchParam.accountTime = [startDateStr, endDateStr];
          this.searchParam.billStartDay = startDateStr;
          this.searchParam.billEndDay = endDateStr;
          this.searchParam.queryType = '1';
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (
          searchData.accountTime &&
          Array.isArray(searchData.accountTime) &&
          searchData.accountTime.length > 0
        ) {
          [this.searchParam.billStartDay, this.searchParam.billEndDay] = searchData.accountTime;
        }

        console.log(' this.searchParam++++', this.searchParam);
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(searchData) {
        Object.assign(this.searchParam, searchData);

        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.accountTime = [startDate, endDate];
        this.searchParam.billStartDay = startDate;
        this.searchParam.billEndDay = endDate;
        this.searchParam.queryType = 1;
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
